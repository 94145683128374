const Play = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.75 3.86719V20.1328L7.89844 19.3828L19.3828 12L7.89844 4.61719L6.75 3.86719ZM8.25 6.60938L16.6172 12L8.25 17.3906V6.60938Z"
        fill="black"
      />
    </svg>
  )
}

export default Play
