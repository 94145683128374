import axios from 'axios'
import { NEXT_CONVERISON_API } from '@components/utils/constants'

export const facebookEventHandler = (eventData: any) => {
  return
  // axios
  //   .post(NEXT_CONVERISON_API, eventData)
  //   .then((response) => {
  //     return response
  //   })
  //   .catch((error) => {
  //     console.error('Error sending event to Facebook ::', error)
  //   })
}
