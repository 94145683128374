import Button from '@components/ui/AddButton'
import { Dialog, Transition } from '@headlessui/react'
import { Image } from '@components/common'
import { Fragment, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import SwiperCore, { Navigation } from 'swiper'
import Link from 'next/link'
import {
  BTN_ADD_TO_FAVORITES,
  BTN_NOTIFY_ME,
  BTN_PRE_ORDER,
  GENERAL_ADD_TO_BASKET,
  GENERAL_CHANGE_FREE_GIFT,
  GENERAL_ENGRAVING,
  IMG_PLACEHOLDER,
  ITEM_TYPE_ADDON,
  ADDED_TO_WISH,
  PROD_ADDED_SUCCESSFULLY,
  INSUFFICIENT_STOCK,
  PROD_ADDED,
} from '@components/utils/textVariables'
import { round } from '@framework/utils/lodash'
import { generateUri } from '@commerce/utils/uri-util'
import axios from '@services/axiosInterceptorInstance'
import {
  AppsFlyerKeys,
  AppsFlyerParams,
  EmptyGuid,
  FacebookEvents,
  Messages,
  NEXT_BULK_ADD_TO_CART,
  NEXT_CREATE_WISHLIST,
  NEXT_GET_PRODUCT,
  NEXT_GET_PRODUCT_QUICK_VIEW,
  NEXT_UPDATE_CART_INFO,
  PRODUCTS_SLUG_PREFIX,
} from '@components/utils/constants'
import { useUI } from '@components/ui'
import AttributesHandler from '../ProductView/AttributesHandler'
import cartHandler from '@components/services/cart'
import { matchStrings, priceFormat } from '@framework/utils/parse-util'
import Spinner from '@components/ui/Spinner'
import {
  getBrowserIdForFBConversion,
  getCategories,
  getColor,
  getCommonAppsFlyerValue,
  handleBrokenImageError,
  getIpAddressForFBConversion,
  getDateOfBirthHash,
  getGenderHash,
  isCartAssociated,
  openLoginView,
  openNewCartPage,
  validateAddToCart,
  getCurrentPage,
  getColorAndSize,
  eventIdForFB,
} from '@framework/utils/app-util'
import { useRouter } from 'next/router'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { Cross, Heart, Play } from '@components/icons'
import { getSHA256Hash } from '@framework/utils/cipher'
import { BasketServices } from 'services/basket/index.service'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

declare const window: any
declare const AF: any

SwiperCore.use([Navigation])

export default function FreeGiftChange({
  isQuickview,
  setQuickview,
  productData,
  collectionName,
  deviceInfo,
}: any) {
  const {
    openNotifyUser,
    setNotifyProduct,
    addToWishlist,
    openWishlist,
    basketId,
    setCartItems,
    user,
    openCart,
    setSidebarView,
    cartItems,
    setAlert,
    wishListItems,
  } = useUI()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const trackMoEngageEvent = useMoEngageEvent()
  const { isMobile, isIPadorTablet } = deviceInfo
  const [quickViewData, setQuickViewData] = useState<any>(undefined)
  const [selectedAttrData, setSelectedAttrData] = useState({
    productId: quickViewData?.recordId,
    stockCode: quickViewData?.stockCode,
    ...quickViewData,
  })
  const [updatedProduct, setUpdatedProduct] = useState<any>(null)
  const [isEngravingOpen, showEngravingModal] = useState(false)

  const product = updatedProduct || productData

  const [currentVariantData, setCurrentVariantData] = useState({})
  const { asPath } = useRouter()

  useEffect(() => {
    const currentSizeAndColor = {
      'global.colour': product?.customAttributes?.filter(
        (attr: any) => attr?.key === 'global.colour'
      )[0]?.value,
      'clothing.size': product?.customAttributes?.filter(
        (attr: any) => attr.key === 'clothing.size'
      )[0]?.value,
    }
    setCurrentVariantData(currentSizeAndColor)
  }, [])

  const saving = product?.listPrice?.raw?.withTax - product?.price?.raw?.withTax
  const discount = round((saving / product?.listPrice?.raw?.withTax) * 100, 0)
  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }
  const fetchProduct = async () => {
    if (productData?.slug) {
      const productSlug = product?.slug?.replace(PRODUCTS_SLUG_PREFIX, '')
      // const response: any = await axios.post(NEXT_GET_PRODUCT, { slug: productData?.slug })
      const response: any = await axios.post(NEXT_GET_PRODUCT, {
        slug: productSlug?.startsWith('/')
          ? productSlug?.substring(1)
          : productSlug,
      })

      if (response?.data?.product) {
        setUpdatedProduct(response?.data?.product)
        setSelectedAttrData({
          productId: response?.data?.product?.recordId,
          stockCode: response?.data?.product?.stockCode,
          ...response?.data?.product,
        })
        const attr = {
          productId: response?.data?.product?.recordId,
          stockCode: response?.data?.product?.stockCode,
          ...response?.data?.product,
        }
      }
    }
  }
  const getlineItemSizeWithoutSlug = (product: any) =>
    product?.variantProducts
      ?.filter((vp: any) => vp?.stockCode === product?.stockCode)[0]
      ?.attributes.filter((va: any) => va.fieldCode === 'clothing.size')[0]
      ?.fieldValue
  const fetchIsQuickView = () => {
    if (isQuickview) {
      const loadView = async (slug: string) => {
        const { data: productQuickViewData }: any = await axios.post(
          NEXT_GET_PRODUCT_QUICK_VIEW,
          { slug: slug }
        )
        setQuickViewData(productQuickViewData?.product)
      }

      if (productData?.slug) loadView(productData?.slug)
    } else {
      setQuickViewData(undefined)
    }
    return [isQuickview]
  }

  useEffect(() => {
    fetchProduct()
    fetchIsQuickView()
  }, [productData?.slug])

  if (!product) {
    return null
  }

  const handleNotification = () => {
    openNotifyUser(product?.recordId)
    setNotifyProduct(product)
  }

  const addToCartGAEvent = async (productData: any) => {
    const eventId = eventIdForFB()
    trackGoogleAnalyticsEvent(
      GA_EVENT.ADD_TO_CART,
      {
        ecommerce: {
          items: [
            {
              item_name: productData?.name,
              item_brand: productData?.brand,
              item_category2: getCategories(productData).category2,
              item_category: getCategories(productData).category,
              item_variant: getColorAndSize(productData),
              color: getColor(productData),
              quantity: productData?.qty,
              item_id: productData?.sku,
              item_size: getlineItemSizeWithoutSlug(productData),
              price: productData?.price?.raw?.withTax,
              item_var_id: productData?.stockCode,
              item_list_name: getCategories(productData).category,
              index: 1,
            },
          ],
          cart_quantity: 1,
          total_value: productData?.price?.raw?.withTax,
          current_page: getCurrentPage(),
          section_title: 'Quick Add',
          event_id: eventId,
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      },
      window
    )

    const mobileNumber = user?.mobile || user?.telephone
    let facebookeventData = {
      data: [
        {
          event_name: FacebookEvents.ADD_TO_CART,
          event_time: Math.floor(new Date().getTime() / 1000),
          event_id: eventId,
          action_source: 'website',
          user_data: {
            em: [user?.email ? await getSHA256Hash(user?.email) : ''],
            ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
            fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
            ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: getBrowserIdForFBConversion(),
            client_ip_address: (await getIpAddressForFBConversion()) || null,
            external_id: mobileNumber
              ? await getSHA256Hash(mobileNumber)
              : null,
            db: await getDateOfBirthHash(user),
            ge: await getGenderHash(user?.gender),
            ct: null,
            zp: null,
            country: await getSHA256Hash('in'),
          },
          custom_data: {
            currency: 'INR',
            num_items: 1,
            content_name: getCurrentPage(),
            value: productData?.price?.raw?.withTax,
            content_category: getCategories(productData).category,
            content_ids: [productData?.stockCode],
            content_type: 'product',
            contents: [
              {
                id: productData?.recordId ?? productData?.productId,
                quantity: 1,
                item_price: productData?.price?.raw?.withTax,
                delivery_category: 'home_delivery',
              },
            ],
          },
        },
      ],
    }

    facebookEventHandler(facebookeventData)
  }

  const buttonTitle = () => {
    let buttonConfig: any = {
      title: GENERAL_CHANGE_FREE_GIFT,

      validateAction: async () => {
        const isValid = validateAddToCart(
          selectedAttrData?.recordId ?? selectedAttrData?.productId,
          cartItems
        )
        if (!isValid) {
          setAlert({
            type: 'error',
            msg: Messages.Errors['CART_ITEM_QTY_LIMIT_EXCEEDED'],
          })
        }
        return isValid
      },

      action: async () => {
        const item = await cartHandler().addToCart(
          {
            basketId: basketId,
            productId: selectedAttrData?.productId,
            qty: 1,
            // manualUnitPrice: 0,
            stockCode: selectedAttrData?.stockCode,
            userId: user?.userId,
            isAssociated: isCartAssociated(cartItems), //user?.isAssociated,
          },
          'ADD',
          { product: selectedAttrData },
          (item: any) => {
            // setCartItems(item);
            // openCart();
          }
        )
        // setCartItems(item)
        if (
          item?.message &&
          !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
        ) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else if (item?.id === EmptyGuid) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else {
          setAlert({ type: 'success', msg: PROD_ADDED })
          addToCartGAEvent(product)
          setCartItems(item)
          //           openCart();
          openNewCartPage(openCart)
        }

        setQuickview(undefined)
        // trackGoogleAnalyticsEvent(
        //   GA_EVENT.ADD_TO_CART,
        //   {
        //     ecommerce: {
        //       items: [
        //         {
        //           item_name: product?.name,
        //           item_brand: product?.brand,
        //           item_category2: getCategories(product).category2,
        //           item_category: getCategories(product).category,
        //           item_variant: product?.attributes.find(
        //             (x: any) => x?.key === 'product.colours'
        //           )?.value,
        //           color: product?.attributes.find(
        //             (x: any) => x?.key === 'product.colours'
        //           )?.value,
        //           quantity: 1,
        //           item_id: product?.sku,
        //           // item_id: product?.productCode,
        //           // item_size: getlineItemSizeWithoutSlug(product),
        //           price: product?.price?.raw?.withTax,
        //           item_var_id: product?.stockCode,
        //           item_list_name: getCategories(product).category,
        //           index: 1,
        //         },
        //       ],
        //       cart_quantity: 1,
        //       total_value: product?.price?.raw?.withTax,
        //       current_page: window?.location?.href,
        //       section_title: product?.name,
        //       item_var_id: product?.stockCode,
        //       loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        //     },
        //   },
        //   window
        // )

        if (typeof AF !== 'undefined') {
          const commonEventValue = getCommonAppsFlyerValue()
          AF('pba', 'event', {
            eventType: 'EVENT',
            eventName: AppsFlyerKeys.AddToCart,
            eventRevenueCurrency: 'INR',
            eventValue: {
              [AppsFlyerParams.AFEventParam3]: window?.location?.href,
              [AppsFlyerParams.AFEventParamRevenue]:
                product?.price?.raw?.withTax + '',
              [AppsFlyerParams.AFEventParamContent]: product?.name,
              [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
              [AppsFlyerParams.AFEventParamPrice]:
                product?.price?.raw?.withTax + '',
              [AppsFlyerParams.AFEventParamQuantity]: 1 + '',
              [AppsFlyerParams.AFEventParam5]:
                product?.mappedCategories[2]?.categoryName || '',
              [AppsFlyerParams.AFEventParam6]:
                product?.mappedCategories[0]?.categoryName || '',
              commonEventValue,
            },
          })
        }

        trackMoEngageEvent(
          MO_ENGAGE_EVENT.ADD_TO_CART,
          {
            Product_size: getlineItemSizeWithoutSlug(product),
            URL: window?.location?.href,
            Cart_quantity: 1,
            Cart_total: product?.price?.raw?.withTax,
            Product_name: product?.name,
            Product_ID: product?.stockCode,
            Price: product?.price?.raw?.withTax,
            label: 'Quick View',
            action: 'Click',
            Quantity: 1,
            Image: product?.image,
            Product_category: product?.mappedCategories[0]?.categoryName,
            Product_category_2: product?.mappedCategories[1]?.categoryName,
            Product_color: product?.variantGroupCode,
          },
          window
        )
      },
      shortMessage: '',
    }
    if (selectedAttrData.currentStock <= 0 && !product.preOrder.isEnabled) {
      if (
        !product?.flags?.sellWithoutInventory ||
        !selectedAttrData.sellWithoutInventory
      ) {
        buttonConfig.title = BTN_NOTIFY_ME
        buttonConfig.action = async () => handleNotification()
        buttonConfig.type = 'button'
      }
    } else if (
      product?.preOrder?.isEnabled &&
      selectedAttrData?.currentStock <= 0
    ) {
      if (
        product?.preOrder?.currentStock < product.preOrder.maxStock &&
        (!product.flags.sellWithoutInventory ||
          selectedAttrData.sellWithoutInventory)
      ) {
        buttonConfig.title = BTN_PRE_ORDER
        buttonConfig.shortMessage = product.preOrder.shortMessage
        return buttonConfig
      } else if (
        product?.flags?.sellWithoutInventory ||
        selectedAttrData.sellWithoutInventory
      ) {
        buttonConfig = {
          title: GENERAL_ADD_TO_BASKET,

          validateAction: async () => {
            const isValid = validateAddToCart(
              selectedAttrData?.recordId ?? selectedAttrData?.productId,
              cartItems
            )
            if (!isValid) {
              setAlert({
                type: 'error',
                msg: Messages.Errors['CART_ITEM_QTY_LIMIT_EXCEEDED'],
              })
            }
            return isValid
          },

          action: async () => {
            const item = await cartHandler().addToCart(
              {
                basketId: basketId,
                productId: selectedAttrData.productId,
                qty: 1,
                // manualUnitPrice: 0,
                stockCode: selectedAttrData.stockCode,
                userId: user.userId,
                isAssociated: isCartAssociated(cartItems), //user.isAssociated,
              },
              'ADD',
              { product: selectedAttrData },
              (item: any) => {
                // setCartItems(item);
                // openCart();
              }
            )
            // setCartItems(item)
            if (
              item?.message &&
              !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
            ) {
              setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
            } else if (item?.id === EmptyGuid) {
              setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
            } else {
              setAlert({ type: 'success', msg: PROD_ADDED })
              setCartItems(item)
              addToCartGAEvent(product)
              //               openCart();
              openNewCartPage(openCart)
            }
            // trackGoogleAnalyticsEvent(
            //   GA_EVENT.ADD_TO_CART,
            //   {
            //     ecommerce: {
            //       items: [
            //         {
            //           item_name: product?.name,
            //           item_brand: product?.brand,
            //           item_category2: getCategories(product).category2,
            //           item_category: getCategories(product).category,
            //           item_variant: product?.attributes.find(
            //             (x: any) => x?.key === 'product.colours'
            //           )?.value,
            //           color: product?.attributes.find(
            //             (x: any) => x?.key === 'product.colours'
            //           )?.value,
            //           quantity: 1,
            //           item_id: product?.sku,
            //           // item_id: product?.productCode,
            //           // item_size: getlineItemSizeWithoutSlug(product),
            //           price: product?.price?.raw?.withTax,
            //           item_var_id: product?.stockCode,
            //           // item_list_name: 'PDP',
            //           item_list_name: getCategories(product).category,
            //           index: 1,
            //         },
            //       ],
            //       cart_quantity: 1,
            //       total_value: product?.price?.raw?.withTax,
            //       current_page: window?.location?.href,
            //       section_title: product?.name,
            //       loggedin_status: !!(
            //         user?.userId && user?.userId !== EmptyGuid
            //       ),
            //     },
            //   },
            //   window
            // )

            if (typeof AF !== 'undefined') {
              const commonEventValue = getCommonAppsFlyerValue()
              AF('pba', 'event', {
                eventType: 'EVENT',
                eventName: AppsFlyerKeys.AddToCart,
                eventRevenueCurrency: 'INR',
                eventValue: {
                  [AppsFlyerParams.AFEventParam3]: window?.location?.href,
                  [AppsFlyerParams.AFEventParamRevenue]:
                    product?.price?.raw?.withTax + '',
                  [AppsFlyerParams.AFEventParamContent]: product?.name,
                  [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
                  [AppsFlyerParams.AFEventParamPrice]:
                    product?.price?.raw?.withTax + '',
                  [AppsFlyerParams.AFEventParamQuantity]: 1 + '',
                  [AppsFlyerParams.AFEventParam5]:
                    product?.mappedCategories[2]?.categoryName || '',
                  [AppsFlyerParams.AFEventParam6]:
                    product?.mappedCategories[0]?.categoryName || '',
                  ...commonEventValue,
                },
              })
            }

            trackMoEngageEvent(
              MO_ENGAGE_EVENT.ADD_TO_CART,
              {
                Product_size: getlineItemSizeWithoutSlug(product),
                URL: window?.location?.href,
                Cart_quantity: 1,
                Cart_total: product?.price?.raw?.withTax,
                Product_name: product?.name,
                Product_ID: product?.stockCode,
                Price: product?.price?.raw?.withTax,
                label: 'Quick View',
                action: 'Click',
                Quantity: 1,
                Image: product?.image,
                Product_category: product?.mappedCategories[0]?.categoryName,
                Product_category_2: product?.mappedCategories[1]?.categoryName,
                Product_color: product?.variantGroupCode,
              },
              window
            )
          },
          shortMessage: '',
        }
      } else {
        buttonConfig.title = BTN_NOTIFY_ME
        buttonConfig.action = async () => handleNotification()
        buttonConfig.type = 'button'
        return buttonConfig
      }
    }
    return buttonConfig
  }

  const buttonConfig = buttonTitle()

  const handleEngravingSubmit = (values: any) => {
    const updatedProduct = {
      ...product,
      ...{
        recordId: selectedAttrData.productId,
        stockCode: selectedAttrData.stockCode,
      },
    }
    const addonProducts = product.relatedProducts?.filter(
      (item: any) => item.stockCode === ITEM_TYPE_ADDON
    )
    const addonProductsWithParentProduct = addonProducts.map((item: any) => {
      item.parentProductId = product.recordId
      return item
    })
    const computedProducts = [
      ...addonProductsWithParentProduct,
      updatedProduct,
    ].reduce((acc: any, obj: any) => {
      acc.push({
        ProductId: obj.recordId || obj.productId,
        BasketId: basketId,
        ParentProductId: obj.parentProductId || null,
        Qty: 1,
        DisplayOrder: obj.displayOrder || 0,
        StockCode: obj.stockCode,
        ItemType: obj.itemType || 0,
        CustomInfo1: values.line1 || null,
        CustomInfo2: values.line2 || null,
        CustomInfo3: values.line3 || null,
        CustomInfo4: values.line4 || null,
        CustomInfo5: values.line5 || null,
        ProductName: obj.name,
        ManualUnitPrice: obj.manualUnitPrice || 0.0,
        PostCode: obj.postCode || null,
        IsSubscription: obj.subscriptionEnabled || false,
        IsMembership: obj.hasMembership || false,
        SubscriptionPlanId: obj.subscriptionPlanId || null,
        SubscriptionTermId: obj.subscriptionTermId || null,
        UserSubscriptionPricing: obj.userSubscriptionPricing || 0,
        GiftWrapId: obj.giftWrapConfig || null,
        IsGiftWrapApplied: obj.isGiftWrapApplied || false,
        ItemGroupId: obj.itemGroupId || 0,
        PriceMatchReqId:
          obj.priceMatchReqId || '00000000-0000-0000-0000-000000000000',
      })
      return acc
    }, [])

    const asyncHandler = async () => {
      try {
        // const newCart = await axios.post(NEXT_BULK_ADD_TO_CART, {
        //   basketId,
        //   products: computedProducts,
        // })
        const newCart = await BasketServices.bulkUpdateBasket({
          id: basketId,
          basketLine: computedProducts,
        })
        await axios.post(NEXT_UPDATE_CART_INFO, {
          basketId,
          info: [...Object.values(values)],
          lineInfo: computedProducts,
        })

        setCartItems(newCart.data)
        showEngravingModal(false)
      } catch (error) {
        console.error(':: error ', error)
      }
    }
    asyncHandler()
  }

  const isEngravingAvailable = !!product.relatedProducts?.filter(
    (item: any) => item.stockCode === ITEM_TYPE_ADDON
  ).length

  // This won't work with any quickView also gives an error in build command
  // useEffect(() => {
  //     const ifInWishlist = wishListItems.map((wi: any) => wi.stockCode).includes(product.stockCode)
  //     setIsInWishList(ifInWishlist)
  // },[product,wishListItems])

  const isInWishList = wishListItems
    ?.map((wi: any) => wi?.stockCode)
    ?.includes(selectedAttrData?.stockCode)

  const insertToLocalWishlist = () => {
    addToWishlist(product)
    // setIsInWishList(true)
    setAlert({ type: 'success', msg: ADDED_TO_WISH })
    openWishlist()
  }
  const handleWishList = async () => {
    const accessToken = localStorage?.getItem('user')
    if (accessToken) {
      const createWishlist = async () => {
        try {
          await axios.post(NEXT_CREATE_WISHLIST, {
            id: user.userId,
            productId: product.recordId,
            flag: true,
          })
          insertToLocalWishlist()
        } catch (error) {
          console.error(':: error ', error)
        }
      }
      createWishlist()
    } else {
      setQuickview(undefined)
      openLoginView(asPath)
      setSidebarView('LOGIN_VIEW')
      // insertToLocalWishlist()
    }

    let productAvailability = 'Yes'
    if (product?.currentStock > 0) {
      productAvailability = 'Yes'
    } else {
      productAvailability = 'No'
    }

    trackMoEngageEvent(
      MO_ENGAGE_EVENT.ADD_TO_WISHLIST,
      {
        URL: window?.location?.href,
        Product_name: product?.name,
        Product_category: product?.classification?.mainCategoryName,
        Product_category_2: product?.classification?.category,
        Product_color: '',
        Price: product?.price?.raw?.withTax,
      },
      window
    )

    const eventId = eventIdForFB()
    recordGA4Event(window, GA_EVENT.ADD_TO_WISHLIST, {
      ecommerce: {
        items: [
          {
            item_name: product?.name,
            item_brand: product?.brand,
            item_category2: getCategories(product).category2,
            item_category: getCategories(product).category,
            item_variant: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            color: product?.attributes.find(
              (x: any) => x?.key === 'product.colours'
            )?.value,
            quantity: 1,
            item_id: product?.sku,
            // item_size: getlineItemSizeWithoutSlug(product),
            price: product?.price?.raw?.withTax,
            item_var_id: product?.stockCode,
            item_list_name: getCategories(product).category,
            index: 1,
          },
        ],
        header: collectionName,
        current_page: collectionName,
        availability: productAvailability,
        event_id: eventId,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
    })

    const mobileNumber = user?.mobile || user?.telephone
    let facebookeventData: any = {
      data: [
        {
          event_name: FacebookEvents.ADD_TO_WISHLIST,
          event_time: Math.floor(new Date().getTime() / 1000),
          event_id: eventId,
          action_source: 'website',
          user_data: {
            em: [user?.email ? await getSHA256Hash(user?.email) : ''],
            ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
            fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
            ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: getBrowserIdForFBConversion(),
            client_ip_address: (await getIpAddressForFBConversion()) || null,
            external_id: mobileNumber
              ? await getSHA256Hash(mobileNumber)
              : null,
            db: await getDateOfBirthHash(user),
            ge: await getGenderHash(user?.gender),
            ct: null,
            zp: null,
            country: await getSHA256Hash('in'),
          },
          custom_data: {
            currency: 'INR',
            content_name: getCurrentPage(),
            content_category: getCategories(product).category,
            content_ids: [product?.stockCode],
            content_type: 'product',
            contents: [
              {
                id: product?.recordId ?? product?.productId,
                quantity: 1,
                item_price: product?.price?.raw?.withTax,
                delivery_category: 'home_delivery',
              },
            ],
          },
        },
      ],
    }

    facebookEventHandler(facebookeventData)

    if (typeof AF !== 'undefined') {
      const commonEventValue = getCommonAppsFlyerValue()
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: AppsFlyerKeys.AddToWishlist,
        eventRevenueCurrency: 'INR',
        eventValue: {
          [AppsFlyerParams.AFEventParam3]: collectionName,
          [AppsFlyerParams.AFEventParamContent]: product?.name,
          [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
          [AppsFlyerParams.AFEventParamPrice]:
            product?.price?.raw?.withTax + '',
          [AppsFlyerParams.AFEventParam5]:
            product?.classification?.mainCategoryName || '',
          [AppsFlyerParams.AFEventParam6]:
            product?.classification?.category || '',
          ...commonEventValue,
        },
      })
    }
  }

  const attributeChanged = (fieldCode: string, value: string) => {
    let slug = ''
    quickViewData?.variantProducts?.find((item: any) => {
      item.attributes.find((option: any) => {
        const isFieldCode = option.fieldCode === fieldCode
        const isFieldValue = option.fieldValue === value
        if (isFieldCode && isFieldValue) {
          slug = item.slug
        }
      })
    })

    const variantProductFilter = (vps: any[], fc: string, v: string) => {
      const checkCorrectAttribute = (vpas: any) =>
        vpas.filter((vpa: any) => vpa.fieldCode === fc && vpa.fieldValue === v)
      const optionsWithCorrectAttribute = vps.filter(
        (vp: any) => checkCorrectAttribute(vp.attributes).length > 0
      )
      return optionsWithCorrectAttribute
    }

    const currentSizeAndColor = {
      'global.colour': product?.customAttributes?.filter(
        (attr: any) => attr?.key === 'global.colour'
      )[0]?.value,
      'clothing.size': product?.customAttributes?.filter(
        (attr: any) => attr.key === 'clothing.size'
      )[0]?.value,
    }

    if (matchStrings(fieldCode, 'clothing.size', true)) {
      const selectedColor = quickViewData?.customAttributes?.filter(
        (attr: any) => attr?.key === 'global.colour'
      )[0]?.value
      currentSizeAndColor['clothing.size'] = value
      setCurrentVariantData(currentSizeAndColor)
      const variantsThatMatchColor = variantProductFilter(
        quickViewData?.variantProducts,
        'global.colour',
        selectedColor
      )
      const variantsThatMatchColorAndSize = variantProductFilter(
        variantsThatMatchColor,
        fieldCode,
        value
      )[0]
      // const productCode = product?.stockCode?.substring(0, product?.stockCode?.lastIndexOf("-"));
      // const newStockCode = `${productCode}-${value}`;
      // const newProduct = product?.variantProducts?.find((x: any) => matchStrings(x?.stockCode, newStockCode, true));
      if (variantsThatMatchColorAndSize?.productId) {
        const updatedProduct = {
          ...product,
          ...{
            productId: variantsThatMatchColorAndSize?.productId,
            stockCode: variantsThatMatchColorAndSize.stockCode,
            currentStock: variantsThatMatchColorAndSize.currentStock,
          },
        }
        setSelectedAttrData(updatedProduct)
        setQuickViewData(updatedProduct)
      }
    } else {
      if (slug) {
        const handleAsync = async (slug: string) => {
          const selectedSize = quickViewData?.customAttributes?.filter(
            (attr: any) => attr.key === 'clothing.size'
          )[0]?.value
          currentSizeAndColor['global.colour'] = value
          currentSizeAndColor['clothing.size'] = selectedSize
          setCurrentVariantData(currentSizeAndColor)
          const { data: prodData }: any = await axios.post(NEXT_GET_PRODUCT, {
            slug: slug,
          })
          const variantsthatMatchSize = variantProductFilter(
            prodData?.product?.variantProducts,
            'clothing.size',
            selectedSize
          )
          const variantsthatMatchSizeAndColor = variantProductFilter(
            variantsthatMatchSize,
            fieldCode,
            value
          )[0]
          const updatedProduct = {
            ...prodData?.product,
            ...{
              productId: variantsthatMatchSizeAndColor?.productId,
              stockCode: variantsthatMatchSizeAndColor,
              currentStock: variantsthatMatchSizeAndColor.currentStock,
            },
          }
          setSelectedAttrData(updatedProduct)
          setQuickViewData(updatedProduct)
        }

        slug = slug.replace(PRODUCTS_SLUG_PREFIX, '')
        const newSlug = slug?.startsWith('/') ? slug?.substring(1) : slug
        handleAsync(newSlug)
      }
    }
  }

  return (
    <>
      {/* QUICK VIEW PANEL FROM BOTTOM */}
      <Transition.Root show={isQuickview != undefined} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-9999"
          onClose={() => setQuickview(undefined)}
        >
          <div className="fixed inset-0 left-0 bg-black/[.5]" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none pt-52 small-pt-52">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-400"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-400"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="w-screen pointer-events-auto">
                    <div className="relative z-50 flex flex-col h-full bg-white shadow-xl">
                      <div className="sticky top-0 right-0 z-10 px-6 pt-6 sm:px-6">
                        <div className="flex justify-between">
                          <div>
                            <div className="flex flex-col sm:hidden">
                              <h2 className="mb-1 font-normal text-12 text-brown-light">
                                {quickViewData?.classification?.category}
                              </h2>
                              <h1 className="grid grid-cols-12 mb-2 text-lg font-bold tracking-tight text-black truncate text-ellipsis sm:text-2xl sm:grid-cols-6">
                                <div className="col-span-8 sm:col-span-4">
                                  {quickViewData?.name || quickViewData?.name}{' '}
                                </div>
                              </h1>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                            onClick={() => setQuickview(undefined)}
                          >
                            <span className="sr-only">Close panel</span>
                            <Cross
                              className="relative w-5 h-5 sm:w-8 sm:h-8 -top-3"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>

                      {productData?.slug ? (
                        !quickViewData ? (
                          <>
                            <div className="flex flex-col justify-center py-48 text-center">
                              <Spinner />
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Main */}
                            <div className="px-0 pb-0 overflow-y-auto sm:px-6">
                              <div className="grid grid-cols-1 mx-auto sm:grid-cols-12 sm:gap-10 sm:px-24">
                                <div className="pl-6 mb-4 sm:col-span-7 sm:mb-0 sm:pl-0">
                                  <div className="sticky top-0 grid grid-cols-1 gap-x-1 gap-y-1 home-banner-swiper m-hide-navigation">
                                    <Swiper
                                      slidesPerView={1.8}
                                      spaceBetween={10}
                                      navigation={true}
                                      loop={false}
                                      breakpoints={{
                                        640: {
                                          slidesPerView: 1.8,
                                        },
                                        768: {
                                          slidesPerView: 1,
                                        },
                                        1024: {
                                          slidesPerView: 2,
                                        },
                                      }}
                                    >
                                      <div
                                        role="list"
                                        className="inline-flex mx-4 space-x-0 sm:mx-0 lg:mx-0 lg:space-x-0 lg:grid lg:grid-cols-4 lg:gap-x-0"
                                      >
                                        {quickViewData?.images?.map(
                                          (image: any, idx: number) =>
                                            image?.tag != 'specification' && (
                                              <SwiperSlide
                                                className="px-0"
                                                key={`${idx}-slider`}
                                              >
                                                <div
                                                  key={idx}
                                                  className="inline-flex flex-col w-full text-center cursor-pointer lg:w-auto"
                                                >
                                                  <div className="relative group long-image">
                                                    {image?.image ? (
                                                      <div className="image-container">
                                                        <Link
                                                          legacyBehavior
                                                          href={`/${quickViewData?.link}`}
                                                          passHref
                                                        >
                                                          <img
                                                            src={
                                                              generateUri(
                                                                image?.image,
                                                                'h=1000&fm=webp'
                                                              ) ||
                                                              IMG_PLACEHOLDER
                                                            }
                                                            alt={image.name}
                                                            className="object-cover object-center w-full h-full image pdp-image"
                                                            sizes="320 600 1000"
                                                            width={600}
                                                            height={1000}
                                                            onError={(e: any) =>
                                                              handleBrokenImageError(
                                                                e
                                                              )
                                                            }
                                                          />
                                                        </Link>
                                                      </div>
                                                    ) : (
                                                      <Play className="object-cover object-center w-full h-full" />
                                                    )}
                                                  </div>
                                                </div>
                                              </SwiperSlide>
                                            )
                                        )}
                                      </div>
                                    </Swiper>
                                  </div>
                                </div>
                                <div className="px-0 sm:col-span-5 sm:mt-10 sm:px-0">
                                  <div className="flex-col hidden px-6 sm:flex">
                                    <h2 className="mb-1 text-sm font-normal text-orange-500">
                                      {quickViewData?.classification?.category}
                                    </h2>
                                    <h1 className="grid grid-cols-12 mb-2 text-xl font-bold tracking-tight text-primary sm:text-2xl sm:grid-cols-6">
                                      <div className="col-span-8 sm:col-span-4">
                                        {quickViewData?.name ||
                                          quickViewData?.name}{' '}
                                      </div>
                                      <div className="col-span-4 pr-2 font-semibold text-right sm:col-span-2 sm:pr-0">
                                        <img
                                          alt="Star"
                                          src={`${IMAGE_ASSET_BASE_URL}/icons/star-black.svg`}
                                          width={18}
                                          height={18}
                                          className="relative top-1"
                                        />
                                        <span className="relative inline-block pl-1 text-xs -top-1 text-primary">
                                          {quickViewData?.rating}
                                        </span>
                                      </div>
                                    </h1>
                                  </div>
                                  <div className="flex justify-between px-6 mt-2">
                                    <div className="item-left">
                                      <p className="mb-2 text-sm font-semibold text-primary sm:text-md">
                                        {priceFormat(
                                          quickViewData?.price?.raw?.withTax
                                        )}

                                        {quickViewData?.listPrice?.raw.tax >
                                        0 ? (
                                          <>
                                            <span className="px-2 text-sm font-normal text-gray-500 line-through sm:text-md">
                                              {priceFormat(
                                                quickViewData?.listPrice?.raw
                                                  ?.withTax
                                              )}
                                            </span>
                                            <span className="text-sm font-normal text-emerald-500 sm:text-md">
                                              {discount}% off
                                            </span>
                                          </>
                                        ) : null}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex-1 w-full px-2 sm:px-6 mobile-padding-quick-view hide-swatch-quickview quick-attr-d">
                                    <AttributesHandler
                                      product={quickViewData}
                                      variant={selectedAttrData}
                                      currentVariantData={currentVariantData}
                                      setSelectedAttrData={setSelectedAttrData}
                                      onChange={attributeChanged}
                                      isQuickView={true}
                                      setQuickview={setQuickview}
                                      callToAction={buttonConfig}
                                      currentPage="Free Gift Quick View"
                                      deviceInfo={deviceInfo}
                                    />
                                  </div>
                                  <div className="sticky bottom-0 z-50 flex py-4 mt-6 bg-white border-t border-gray-200 sm:mt-8 sm:px-6">
                                    <>
                                      <div className="flex w-full px-4 mt-0 sm:mt-0 sm:px-0 sm:shadow-lg">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            if (!isInWishList) {
                                              handleWishList()
                                            }
                                          }}
                                          className="sm:flex btn-basic-property-big hidden items-center justify-center px-4 sm:w-24 py-3 -mr-0.5 text-black bg-white border-2 border-black quick-wishlist-border rounded-sm hover:bg-red-100 hover:text-orange-600 sm:px-6 hover:border-orange-600"
                                        >
                                          {isInWishList ? (
                                            <Heart className="flex-shrink-0 w-6 h-6 text-orange-500" />
                                          ) : (
                                            <>
                                              <Heart className="flex-shrink-0 w-6 h-6" />
                                            </>
                                          )}
                                          <span className="sr-only">
                                            {BTN_ADD_TO_FAVORITES}
                                          </span>
                                        </button>
                                        <Link
                                          legacyBehavior
                                          href={`/${quickViewData?.link}`}
                                          passHref
                                        >
                                          <span className="sm:hidden btn-basic-property-big flex font-semibold items-center w-24 quick-wishlist-border justify-center px-4 py-3 -mr-0.5 text-black bg-white border-2 border-black rounded-sm hover:bg-gray-800 hover:text-whitesm:px-6 hover:border-gray-900">
                                            Detail
                                          </span>
                                        </Link>
                                        <Button
                                          title={buttonConfig.title}
                                          validateAction={
                                            buttonConfig.validateAction
                                          }
                                          action={buttonConfig.action}
                                          buttonType={
                                            buttonConfig.type || 'cart'
                                          }
                                        />
                                      </div>
                                      {isEngravingAvailable && (
                                        <button
                                          className="flex items-center justify-center flex-1 max-w-xs px-8 py-3 mt-5 font-medium text-white uppercase bg-gray-400 border border-transparent rounded-sm btn-basic-property-big btn-basic-property hover:bg-pink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500 sm:w-full"
                                          onClick={() =>
                                            showEngravingModal(true)
                                          }
                                        >
                                          <span className="font-bold">
                                            {GENERAL_ENGRAVING}
                                          </span>
                                        </button>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <div className="flex flex-col justify-center w-full py-48 text-center">
                            <h3 className="text-2xl font-semibold text-gray-300">
                              No Data Available for Selected Product
                            </h3>
                          </div>
                        </>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
