const InfoIcon = ({ ...props }) => {
  return (
    <svg
      width={props?.width ? props?.width : '18'}
      height={props?.height ? props?.height : '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.875C4.52002 0.875 0.875 4.52002 0.875 9C0.875 13.48 4.52002 17.125 9 17.125C13.48 17.125 17.125 13.48 17.125 9C17.125 4.52002 13.48 0.875 9 0.875ZM9 2.125C12.8037 2.125 15.875 5.19629 15.875 9C15.875 12.8037 12.8037 15.875 9 15.875C5.19629 15.875 2.125 12.8037 2.125 9C2.125 5.19629 5.19629 2.125 9 2.125ZM8.375 5.25V6.5H9.625V5.25H8.375ZM8.375 7.75V12.75H9.625V7.75H8.375Z"
        fill={props?.pathFillColor ? props?.pathFillColor : '#1565C0'}
      />
    </svg>
  )
}

export default InfoIcon
