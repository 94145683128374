const OrangeSpinner = ({ ...props }) => {
  return (
    <svg 
      width={props?.width ? props?.width : '56'} 
      height={props?.height ? props?.height : '56'}
      className={props?.className ? props?.className : ''}
      viewBox="0 0 56 56" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28" cy="28" r="25.5" stroke="#EB5B25" stroke-opacity="0.08" stroke-width="5"/>
      <mask id="path-2-inside-1_1080_1965" fill="white">
      <path d="M28 2.506C28 1.12197 29.1242 -0.0116396 30.5027 0.112066C34.0545 0.430802 37.5207 1.42589 40.7117 3.05182C44.6488 5.05786 48.0552 7.96722 50.6525 11.542C53.2497 15.1168 54.964 19.2555 55.6553 23.6198C56.3465 27.9841 55.995 32.45 54.6296 36.6525C53.2641 40.8549 50.9235 44.6745 47.799 47.799C44.6745 50.9235 40.8549 53.2641 36.6525 54.6296C32.4501 55.995 27.9841 56.3465 23.6198 55.6553C20.0825 55.095 16.6934 53.8627 13.6326 52.0329C12.4447 51.3227 12.2015 49.7448 13.015 48.6251C13.8285 47.5054 15.3901 47.2705 16.5916 47.9574C19.0019 49.3352 21.6481 50.2685 24.4039 50.705C27.987 51.2725 31.6535 50.9839 35.1037 49.8629C38.5539 48.7418 41.6898 46.8202 44.255 44.255C46.8202 41.6898 48.7419 38.5539 49.8629 35.1037C50.9839 31.6535 51.2725 27.987 50.705 24.4039C50.1375 20.8208 48.73 17.4229 46.5977 14.488C44.4653 11.5531 41.6687 9.1645 38.4363 7.51754C35.9503 6.25086 33.2609 5.45052 30.5011 5.14846C29.1253 4.99788 28 3.89003 28 2.506Z"/>
      </mask>
      <path d="M28 2.506C28 1.12197 29.1242 -0.0116396 30.5027 0.112066C34.0545 0.430802 37.5207 1.42589 40.7117 3.05182C44.6488 5.05786 48.0552 7.96722 50.6525 11.542C53.2497 15.1168 54.964 19.2555 55.6553 23.6198C56.3465 27.9841 55.995 32.45 54.6296 36.6525C53.2641 40.8549 50.9235 44.6745 47.799 47.799C44.6745 50.9235 40.8549 53.2641 36.6525 54.6296C32.4501 55.995 27.9841 56.3465 23.6198 55.6553C20.0825 55.095 16.6934 53.8627 13.6326 52.0329C12.4447 51.3227 12.2015 49.7448 13.015 48.6251C13.8285 47.5054 15.3901 47.2705 16.5916 47.9574C19.0019 49.3352 21.6481 50.2685 24.4039 50.705C27.987 51.2725 31.6535 50.9839 35.1037 49.8629C38.5539 48.7418 41.6898 46.8202 44.255 44.255C46.8202 41.6898 48.7419 38.5539 49.8629 35.1037C50.9839 31.6535 51.2725 27.987 50.705 24.4039C50.1375 20.8208 48.73 17.4229 46.5977 14.488C44.4653 11.5531 41.6687 9.1645 38.4363 7.51754C35.9503 6.25086 33.2609 5.45052 30.5011 5.14846C29.1253 4.99788 28 3.89003 28 2.506Z" stroke="#EB5B25" stroke-width="8" mask="url(#path-2-inside-1_1080_1965)"/>
    </svg>
  )
}
  
export default OrangeSpinner;