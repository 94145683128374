import { EmptyGuid } from '@components/utils/constants'
import { getCurrentPage } from '@framework/utils/app-util'
import { useUI } from '@components/ui/context'
import React from 'react'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

const Logo = ({ className = '', ...props }) => {
  let currentPage = getCurrentPage()
  const { user } = useUI()
  function logoClick() {
    if (currentPage) {
      recordGA4Event(window, GA_EVENT.LOGO_CLICK, {
        current_page: currentPage,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
  }

  return (
    <img
      className="w-auto max-width-150"
      src={`${IMAGE_ASSET_BASE_URL}/icons/logo-head.svg`}
      alt="damensch logo"
      width={140}
      height={30}
      onClick={() => logoClick()}
    />
  )
}

export default Logo
