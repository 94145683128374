// useSection.js
import { LocalStorage } from '@components/utils/payment-constants'
import { useState } from 'react'

const useSection = () => {
  const [section, setSection] = useState('')

  return {
    getSection: () => {
      if (typeof localStorage !== 'undefined') {
        return localStorage?.getItem(LocalStorage.Key.SECTION_NAME) ?? ''
      }
      return ''
    },
    handleSection: (sectionName: string) => {
      setSection(sectionName)
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(LocalStorage.Key.SECTION_NAME, sectionName)
      }
    },
  }
}

export default useSection
